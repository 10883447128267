<template>
  <v-container fluid class="pa-0">

    <!-- Header -->
    <Header :title="$t('headers.home.title')" :description="$t('headers.home.description')"/>

    <!-- Account level -->
    <AccountLevel :level="$store.state.profile.level" :title="$store.state.profile.title"/>

    <!-- Square buttons -->
    <SquareButtonGroup :buttons="buttons"/>

    <!-- Footer -->
    <Footer :quote="true"/>

  </v-container>
</template>

<script>
import Header from '../components/HeaderInfo';
import AccountLevel from '../components/AccountLevel';
import SquareButtonGroup from '../components/SquareButtonGroup';
import Footer from '../components/Footer';

export default {
  name: 'MainHome',
  components: {Header, AccountLevel, SquareButtonGroup, Footer},
  data: () => ({
    buttons: [
      {icon: 'mdi-brain', title: 'nav.exercises', link: '/exercises'},
      {icon: 'mdi-star-circle-outline', title: 'nav.points', link: '/points'},
      {icon: 'mdi-format-list-bulleted-type', title: 'nav.results', link: '/results'}
    ]
  })
};
</script>
