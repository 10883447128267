<template>
  <div id="account-level-container" class="rounded-r-xl">
    <div id="account-level-level"> {{ $t('common.level') + ' ' + level }}</div>
    <div id="account-level-title">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'AccountLevel',
  props: {
    level: {type: Number, required: true},
    title: {type: String, required: true}
  }
};
</script>

<style scoped>
#account-level-container {
  background-color: #336799;
  color: #ffffff;
  height: 90px;
  width: 270px;
}

#account-level-level {
  font-size: 28px;
  height: 45px;
  padding: 5px 5px 0 15px;
}

#account-level-title {
  font-size: 22px;
  font-style: italic;
  font-weight: 300;
  height: 45px;
  padding: 0 5px 5px 15px;
}
</style>
