<template>
  <div style="color: #336799">
    <div v-if="$vuetify.breakpoint.width > 800" class="d-flex flex-row flex-nowrap justify-space-between align-start">
      <div class="flex-grow-1">
        <div id="header-info-title">{{ title }}</div>
        <div id="header-info-description">{{ description }}</div>
      </div>
      <div id="header-info-logo">
        <LogoText style="height: 115px" :fill="'#336799'"/>
      </div>
    </div>
    <div v-else>
      <div id="header-info-title">{{ title }}</div>
      <div id="header-info-description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import LogoText from '../assets/LogoText';

export default {
  name: 'HeaderInfo',
  components: {LogoText},
  props: {
    title: {type: String, required: true},
    description: {type: String, required: true}
  }
};
</script>

<style scoped>
#header-info-title {
  height: 60px;
  font-size: 29px;
  font-weight: 700;
  padding: 15px 50px 0 15px;
}

#header-info-description {
  height: 95px;
  font-size: 20px;
  padding: 0 50px 30px 15px;
}

#header-info-logo {
  height: 115px;
  margin: 20px;
  width: 182px;
}

@media (max-width: 800px) {
  #header-info-title, #header-info-description {
    height: auto;
  }
}
</style>
